import { useTranslation } from "react-i18next";
import { Box } from "@components";
import { Typography } from "@mui/material";
import { welcomeBackground, welcomeDots } from "../../res/icons";
import { useNavigate } from "react-router-dom";

const Default = (_props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  navigate(`/admin/`);

  const nameStyle = {
    color: "white",
    fontFamily: "Conquera Heavy",
    fontSize: "19px",
    display: "inline-block"
  };

  return (
    <Box width="100%">
      <Box
        m="16px"
        px="20px"
        sx={{
          display: "flex",
          alignItems: "center",
          height: 104,
          borderRadius: "16px",
          backgroundImage: `url(${welcomeBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "0px 30%",
          textAlign: "center"
        }}
      >
        <Box>
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                color: "white",
                fontFamily: "SF Pro Text Regular",
                fontSize: "17px",
                textAlign: "left",
                marginRight: "4px"
              }}
            >
              {t("main.welcomeToThe")}
            </Typography>
            <Typography sx={nameStyle}>23</Typography>
            <Box src={welcomeDots} component="img"></Box>
            <Typography sx={nameStyle}>23</Typography>
          </Box>

          <Typography
            sx={{
              color: "white",
              fontFamily: "SF Pro Text Regular",
              fontSize: "15px",
              textAlign: "left"
            }}
          >
            {t("main.exploreOurUnique")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Default;
